import { useLocation, useNavigate } from 'react-router';
import { useCallback } from 'react';
import type { PublicRoute } from '../Navigation/AuthenticationRoutes';
import { previousNavigationMapping } from '../Navigation/AuthenticationRoutes';

interface UseAuthNavigation {
    goBack: () => void;
    hasPreviousRoute: boolean;
}

const useAuthNavigation = (): UseAuthNavigation => {
    const location = useLocation();
    const navigate = useNavigate();

    const previousRoute = previousNavigationMapping[location.pathname as PublicRoute];

    const goBack = useCallback(() => {
        const doesAnyHistoryEntryExist = location.key !== 'default';

        // If there are any entries in the React router history stack, we can use the history stack to navigate
        if (doesAnyHistoryEntryExist) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigate(-1);
        } else if (previousRoute) {
            // If there are no entries yet, it means that this is the first page they are visiting after page load.
            // If they navigated to a nested route directly, we still want to provide a way to navigate to the previous route in the auth flow.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigate(previousRoute);
        }
    }, [location, navigate, previousRoute]);

    return {
        goBack,
        hasPreviousRoute: !!previousRoute,
    };
};

export default useAuthNavigation;
