import { createContext } from 'react';
import type { AuthFlowEnvironment } from '../../AuthFlowEnvironment';
import type { MySurpriseAuthFlowState } from '../../../../../ssr/src/Outlet/MySurpriseAuthFlow/MySurpriseAuthFlowSchema';

interface ProviderProps {
    authFlowEnvironment: AuthFlowEnvironment;
    loader?: JSX.Element;
    loginRedirectPath?: string | null;
    preloadedState?: MySurpriseAuthFlowState;
}

type Context = MySurpriseAuthFlowState & { authFlowEnvironment: AuthFlowEnvironment };

export const AuthFlowStateContext = createContext<Context>({} as unknown as Context);

export type {
    ProviderProps,
    Context,
};
