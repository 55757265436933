import type { FC, FormEvent, MouseEvent } from 'react';
import { useContext, useState } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router';
import Input from '../../../../../general/Input/Input';
import Button from '../../../../../general/Button/Button';
import ButtonStack from '../../../../../general/Button/ButtonStack';
import { UserLookupContext } from '../../../Provider/UserLookupProvider/UserLookupProvider';
import GoogleLogin from '../GoogleLogin/GoogleLogin';
import styles from './Welcome.module.scss';
import Message, { MessageType } from '../../Message/Message';
import { PublicRoute } from '../../../Navigation/AuthenticationRoutes';
import useTranslate from '../../../../../general/Translation/hooks/UseTranslate';
import BottomSection from '../BottomSection/BottomSection';
import { AuthFlowEnvironment } from '../../../AuthFlowEnvironment';
import { AuthFlowStateContext } from '../../../Provider/AuthFlowStateProvider/AuthFlowStateContext';
import Url from '../../../../../../js/util/Url';
import { window } from '../../../../../../js/globals';
import NotificationBox from '../../../../../../stories/Molecules/NotificationBox/NotificationBox';

const Welcome: FC = () => {
    const {
        errorMessage: userLookupErrorMessage,
        isError: isUserLookupErroneous,
        isPending: isUserLookupPending,
        lookupUser,
        userLookup,
    } = useContext(UserLookupContext);
    const [email, setEmail] = useState(userLookup?.email ?? '');
    const navigate = useNavigate();
    const { authFlowEnvironment, loginRedirectPath, urls } = useContext(AuthFlowStateContext);
    const translate = useTranslate();
    const location = useLocation();

    const currentPath = Url.getPathWithoutSubLanguage(window?.location.href ?? '');

    const [isAccountDeleted] = useState(() => (new URLSearchParams(location.search)).has('account_deleted'));

    const onUserLookup = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        lookupUser(email);
    };

    const onRegisterClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(PublicRoute.REGISTER);
    };

    return (
        <div>
            {isAccountDeleted && <NotificationBox type="info" className="mb-8">{translate('auth', 'delete_account_success')}</NotificationBox>}
            <form onSubmit={onUserLookup}>
                <div>
                    <h2 className="mb-6">{translate('auth', 'welcome_title')}</h2>
                    <p className="mb-8">{translate('auth', 'enter_email_subtitle')}</p>

                    {!isUserLookupPending && (
                        <>
                            {!isUserLookupErroneous && userLookup && !userLookup.exists && (
                                <Message type={MessageType.Error} message={translate('auth', 'email_unknown')} />
                            )}

                            {isUserLookupErroneous && userLookupErrorMessage && <Message type={MessageType.Error} message={userLookupErrorMessage} />}
                        </>
                    )}

                    <Input
                        label={translate('auth', 'email_label')}
                        type="email"
                        value={email}
                        onChange={(value) => setEmail(value)}
                    />

                    <ButtonStack isFluid className="mt-4">
                        <Button type="submit" isPrimary isLoading={isUserLookupPending} disabled={!email && authFlowEnvironment === AuthFlowEnvironment.DRAWER}>
                            {translate('auth', 'continue_button')}
                        </Button>
                    </ButtonStack>
                </div>
            </form>

            <div className={classNames('mt-4 mb-4', styles.alternative)}>
                {translate('auth', 'or')}
            </div>

            <GoogleLogin url={urls.googleLogin} loginRedirectPath={loginRedirectPath ?? currentPath} />

            <BottomSection className="mt-10" borderTop={authFlowEnvironment === AuthFlowEnvironment.DRAWER}>
                {translate('auth', 'create_account_explanation')}
                {' '}
                <a onClick={onRegisterClick}>{translate('auth', 'create_account_button')}</a>
            </BottomSection>
        </div>
    );
};

export default Welcome;
