import { z } from 'zod';
import EmptyPhpRecord from './EmptyPhpRecord';

const TranslationSchema = z.record(z.string());
const TranslationsSchema = z.union([z.record(TranslationSchema), EmptyPhpRecord]);

export type Translation = z.infer<typeof TranslationSchema>;
export type Translations = z.infer<typeof TranslationsSchema>;

export default TranslationsSchema;
