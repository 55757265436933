import type { FC, FormEvent, MouseEvent } from 'react';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { RegistrationContext } from '../../../../Provider/RegistrationProvider/RegistrationProvider';
import Input from '../../../../../../general/Input/Input';
import { PublicRoute } from '../../../../Navigation/AuthenticationRoutes';
import Button from '../../../../../../general/Button/Button';
import Message, { MessageType } from '../../../Message/Message';
import ButtonStack from '../../../../../../general/Button/ButtonStack';
import useTranslate from '../../../../../../general/Translation/hooks/UseTranslate';
import { AuthFlowEnvironment } from '../../../../AuthFlowEnvironment';
import BottomSection from '../../BottomSection/BottomSection';
import { AuthFlowStateContext } from '../../../../Provider/AuthFlowStateProvider/AuthFlowStateContext';
import type { FetchResponseNotOkError } from '../../../../../../../js/api/FetchResponseNotOkError';
import type { EmailAvailabilityResponse } from '../../../../Query/CheckEmailAvailabilityMutation';
import checkEmailAvailabilityMutation from '../../../../Query/CheckEmailAvailabilityMutation';

const CheckEmailAvailabilityForm: FC = () => {
    const { authFlowEnvironment } = useContext(AuthFlowStateContext);
    const { email, setEmail } = useContext(RegistrationContext);
    const navigate = useNavigate();
    const translate = useTranslate();

    const [emailAvailabilityCheckErrorMessage, setEmailAvailabilityCheckErrorMessage] = useState<string | undefined>();

    const parseError = (error: FetchResponseNotOkError) => {
        error.response.json()
            .then((data: { message: string }) => setEmailAvailabilityCheckErrorMessage(data.message || translate('auth', 'generic_error_message')))
            .catch(() => setEmailAvailabilityCheckErrorMessage(translate('auth', 'generic_error_message')));
    };

    const {
        data: emailAvailability,
        isError: isEmailAvailabilityCheckErroneous,
        isPending: isEmailAvailabilityCheckPending,
        mutate: checkEmailAvailability,
    } = useMutation<EmailAvailabilityResponse, FetchResponseNotOkError, string>({
        ...checkEmailAvailabilityMutation(),
        onError: parseError,
        onSuccess: ({ available }) => {
            if (available) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                navigate(PublicRoute.REGISTER_STEP_2);
            }
        },
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        checkEmailAvailability(email);
    };

    const onLoginRouteRequest = (e: MouseEvent) => {
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(PublicRoute.WELCOME);
    };

    return (
        <form onSubmit={onSubmit}>
            <div>
                <h2 className="mb-6">{translate('auth', 'spread_a_little_happiness')}</h2>
                <p className="mb-8">{translate('auth', 'enter_email_subtitle')}</p>

                {!isEmailAvailabilityCheckPending && (
                    <>
                        {!isEmailAvailabilityCheckErroneous && emailAvailability && !emailAvailability.available && (
                            <Message type={MessageType.Error} message={translate('auth', 'email_already_in_use')}/>
                        )}

                        {isEmailAvailabilityCheckErroneous && emailAvailabilityCheckErrorMessage && <Message type={MessageType.Error} message={emailAvailabilityCheckErrorMessage} />}
                    </>
                )}

                <Input
                    label={translate('auth', 'email_label')}
                    type="email"
                    value={email}
                    onChange={setEmail}
                />

                <ButtonStack isFluid className="mt-4">
                    <Button type="submit" isPrimary disabled={!email || isEmailAvailabilityCheckPending} isLoading={isEmailAvailabilityCheckPending}>
                        {translate('auth', 'continue_button')}
                    </Button>
                </ButtonStack>

                <BottomSection className="mt-10" borderTop={authFlowEnvironment === AuthFlowEnvironment.DRAWER}>
                    {translate('auth', 'already_have_an_account')}
                    {' '}
                    <a onClick={onLoginRouteRequest}>{translate('auth', 'to_login_button')}</a>
                </BottomSection>
            </div>
        </form>
    );
};

export default CheckEmailAvailabilityForm;
