import { z } from 'zod';

const SiteMetadataSchema = z.object({
    assetsUrl: z.string().min(1),
    contextKey: z.string().min(1),
    environment: z.enum(['production', 'test', 'development']),
    experimentFlag: z.string(),
    experimentVariation: z.string(),
    hasActiveCoolerNotification: z.boolean(),
    hasTemperatureNotification: z.boolean(),
    isMobile: z.boolean(),
    language: z.string(),
    siteUrl: z.string().min(1),
    staticUrl: z.string().min(1),
    version: z.string().nullable(),
});

export type SiteMetadata = z.infer<typeof SiteMetadataSchema>;

export default SiteMetadataSchema;
