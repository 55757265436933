import type { FC, FormEvent, MouseEvent } from 'react';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { RegistrationContext } from '../../../../Provider/RegistrationProvider/RegistrationProvider';
import Input from '../../../../../../general/Input/Input';
import Button from '../../../../../../general/Button/Button';
import ButtonStack from '../../../../../../general/Button/ButtonStack';
import Message, { MessageType } from '../../../Message/Message';
import PasswordInput from '../../../../../../general/PasswordInput/PasswordInput';
import PasswordStrengthIndicator from '../../../PasswordStrengthIndicator/PasswordStrengthIndicator';
import { PublicRoute } from '../../../../Navigation/AuthenticationRoutes';
import useTranslate from '../../../../../../general/Translation/hooks/UseTranslate';
import { AuthFlowEnvironment } from '../../../../AuthFlowEnvironment';
import BottomSection from '../../BottomSection/BottomSection';
import { AuthFlowStateContext } from '../../../../Provider/AuthFlowStateProvider/AuthFlowStateContext';
import type { FetchResponseNotOkError } from '../../../../../../../js/api/FetchResponseNotOkError';
import type { RegisterMutationArgs, RegisterResponse } from '../../../../Query/RegisterMutation';
import registerMutation from '../../../../Query/RegisterMutation';

const UserCredentialsForm: FC = () => {
    const { authFlowEnvironment } = useContext(AuthFlowStateContext);
    const navigate = useNavigate();
    const translate = useTranslate();

    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const {
        email,
        firstName,
        lastName,
        password,
        setEmail,
        setPassword,
    } = useContext(RegistrationContext);
    const isPasswordTooShort = password.length < 8;

    const parseError = (error: FetchResponseNotOkError) => {
        error.response.json()
            .then((data: { message: string }) => setErrorMessage(data.message || translate('auth', 'generic_error_message')))
            .catch(() => setErrorMessage(translate('auth', 'generic_error_message')));
    };

    const { isError, isPending, mutate: register } = useMutation<RegisterResponse, FetchResponseNotOkError, RegisterMutationArgs>({
        ...registerMutation(),
        onError: parseError,
        onSuccess: () => navigate(PublicRoute.EMAIL_CONFIRMATION_PENDING, { state: { email } }),
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (isPasswordTooShort) {
            return;
        }

        register({ email, firstName, lastName, password });
    };

    const onLoginRouteRequest = (e: MouseEvent) => {
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(PublicRoute.WELCOME);
    };

    return (
        <form onSubmit={onSubmit}>
            <div>
                <h2 className="mb-6">
                    {translate('auth', 'personal_user_greeting').replace('<name>', firstName)}
                </h2>
                <p className="mb-8">
                    {translate('auth', 'create_password_subtitle')}
                </p>

                {isError && <Message type={MessageType.Error} message={errorMessage ?? ''} />}

                <Input
                    label={translate('auth', 'email_label')}
                    type="email"
                    value={email}
                    onChange={setEmail}
                />

                <PasswordInput
                    label={translate('auth', 'password_label')}
                    password={password}
                    onChange={setPassword}
                    required
                    className="mt-4"
                />

                <PasswordStrengthIndicator className="mt-6" password={password} />

                <ButtonStack isFluid className="mt-4">
                    <Button type="submit" isPrimary disabled={!email || isPasswordTooShort} isLoading={isPending}>
                        {translate('auth', 'verify_email_button')}
                    </Button>
                </ButtonStack>

                <BottomSection className="mt-10" borderTop={authFlowEnvironment === AuthFlowEnvironment.DRAWER}>
                    {translate('auth', 'already_have_an_account')}
                    {' '}
                    <a onClick={onLoginRouteRequest}>{translate('auth', 'to_login_button')}</a>
                </BottomSection>
            </div>
        </form>
    );
};

export default UserCredentialsForm;
