import { z } from 'zod';
import SiteMetadataSchema from '../../Schema/SiteMetadata';
import TranslationsSchema from '../../Schema/Translation';
import StringOrNullToUndefined from '../../Schema/StringOrNullToUndefined';

const MySurpriseAuthFlowSchema = z.object({
    basePath: z.string().min(1),
    loginRedirectPath: z.string().nullable().optional(),
    path: z.string().min(1),
    resetTokenValidationResult: z.object({
        email: StringOrNullToUndefined,
        token: z.string().min(1),
        valid: z.boolean(),
    }).optional(),
    siteMetaData: SiteMetadataSchema,
    translations: TranslationsSchema,
    urls: z.object({
        googleLogin: z.string().min(1),
        mysurprise: z.string().min(1),
        notFound: z.string().min(1),
    }),
});

export type MySurpriseAuthFlowState = z.infer<typeof MySurpriseAuthFlowSchema>;

export default MySurpriseAuthFlowSchema;
